import React from "react";
import { Header } from "src/components/header";

const DESKTOP_TEAM = [
  {
    name: "IM",
    title: "CEO",
    img: "IM.jpeg",
  },
  {
    name: "NAM",
    title: "Head of Compliance and Finance",
    img: "Nam.jpeg",
  },
  {
    name: "Calvin",
    title: "Head of Business Development & Operations",
    img: "Calvin.jpeg",
  },
  {
    name: "Frankie Lee",
    title: "Head of Metaverse",
    img: "Frankie Lee.jpeg",
  },
  {
    name: "G.T",
    title: "NFT Ambassador",
    img: "GT.jpeg",
  },
  {
    name: "Belle",
    title: "Thai Ambassador",
    img: "Belle.jpeg",
  },
  {
    name: "J.Hue",
    title: "Lead Digital Artist",
    img: "JHue.jpeg",
  },
  {
    name: "Z.F",
    title: "Head of Metaverse",
    img: "ZF.jpeg",
  },
  {
    name: "Ken",
    title: "Technical Advisor",
    img: "Ken.jpeg",
  },
  {
    name: "M.Y",
    title: "Creative Director",
    img: "MY.jpeg",
  },
  {
    name: "Sindy",
    title: "Community Manager",
    img: "Cindy.jpeg",
  },
  {
    name: "Anson",
    title: "Metaverse Architect",
    img: "Anson.jpeg",
  },
];

export const Team = (props: any) => {
  return (
    <div className="team-section">
      <div className="flex-col center-center">
        <Header header={`ZEETOX TEAM`} />

        <div className="team-profile">
          {DESKTOP_TEAM.map((item, i) => {
            return (
              <div key={i}>
                <img
                  src={`/assets/teams/${item.img}`}
                  alt={`${item.name}-icon`}
                />
                <h2>{item.name}</h2>
                <p>{item.title}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
