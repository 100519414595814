import React from "react";

export const NftLaunch = (props: any) => {
  return (
    <div className="launch-section">
      <div className="wrapper">
        <img src="/assets/Zeetox NFT Value_Desktop_text.svg" alt="svg" />
      </div>
    </div>
  );
};
