import React from "react";
import { Container, Footer, Menu } from "../../components";
import { Cert } from "./cert";
import { Intro } from "./intro";
import { Mint } from "./mint";
import { NftLaunch } from "./nft-launch";
import { NftPrice } from "./nft-price";
import { Promo } from "./promo";
import { Qualifications } from "./qualifications";
import { Team } from "./team";
import { Utilities } from "./utilities";

export const Home = (props: any) => {
  return (
    <Container>
      <div className="home-page">
        {/* Menu */}
        <Menu />

        {/* Mint Section */}
        <Mint />

        {/* Intro Section */}
        <Intro />

        {/* NFT Price Section */}
        <NftPrice />

        {/* NFT Launch */}
        <NftLaunch />

        {/* Utilities Section */}
        <Utilities />

        {/* Promo Section */}
        <Promo />

        {/* Cert Section */}
        <Cert />

        {/* Qualifications Section */}
        <Qualifications />

        {/* Team Section */}
        <Team />

        <Footer />
      </div>
    </Container>
  );
};
