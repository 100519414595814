import React from "react";
import { Web3Btn } from "../web3-btn";

export const Menu = (props: any) => {
  const toPage = (page: string) => {
    window.open(page);
  };

  return (
    <div className="menu">
      <div className="wrapper flex-row end-center">
        {/* Menu */}
        <Web3Btn />

        {/* Social Icons */}
        <div className="social-icons">
          <img
            src="/assets/twitter.png"
            alt="twitter-icon"
            onClick={() => toPage("https://twitter.com/zeetox_io")}
          />
          <img
            src="/assets/tg.png"
            alt="tg-icon"
            onClick={() => toPage("https://t.me/zeetox")}
          />
          <img
            src="/assets/Icon_3_Website_new.png"
            alt="website"
            onClick={() => toPage("https://zeetox.io")}
          />
        </div>
      </div>
    </div>
  );
};
