import React from "react";
import { Header } from "src/components/header";

const Q_IMGS = ["q1.png", "q2.png", "q3.png", "q4.png", "q5.png"];

export const Qualifications = (props: any) => {
  return (
    <div className="qualifications-section">
      <div className="opacity white" />
      <div className="wrapper flex-col center-center">
        <Header header={`Qualifications`} />

        <div className="qualification-icons">
          {Q_IMGS.map((item, i) => {
            return <img src={`/assets/${item}`} key={i} alt={`q${i}-icon`} />;
          })}
        </div>
      </div>
    </div>
  );
};
