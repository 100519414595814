import React from "react";
import { Header } from "src/components/header";

export const NftPrice = (props: any) => {
  return (
    <div className="nft-price-section">
      <div>
        <div className="wrapper flex-row s-wrapper">
          <div className="flex-col full-flex">
            <video autoPlay={false} width={480} controls>
              <source src="/assets/video.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="flex-col center-end">
            <Header header={"Zeetox NFT Alpha"} />

            <div className="price-info">
              <h1>700 NFTs</h1>
              <h1>2 ETH</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
