import React from "react";
import { Header } from "src/components/header";

export const Utilities = (props: any) => {
  return (
    <div className="utilities-section">
      <div className="wrapper flex-row center-center">
        <div className='utilities-blk'>
          <Header header={`BENEFITS & UTILITIES`} subHeader="EXCLUSIVE FOR NFT HOLDERS" />

          <ul>
            <li>Exclusive 20% off Zeetox products</li>
            <li>Access to Zeetox latest products</li>
            <li>Zeetox NFT Airdrops</li>
            <li>Priority to enter second round of NFT</li>
            <li>Zeetox cannabis journey</li>
          </ul>
        </div>

        <div className="utilities-img">
          <img src="/assets/utility_1.png" alt="utility1-img" />
          <img src="/assets/utility_2.png" alt="utility2-img" />
          <img src="/assets/utility_3.jpg" alt="utility3-img" />
          <img src="/assets/utility_4.jpg" alt="utility4-img" />
        </div>
      </div>
    </div>
  );
};
