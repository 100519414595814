import { getDefaultProvider } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
require("dotenv").config();
const { Mainnet } = require("@usedapp/core");

const NETWORK = process.env.REACT_APP_NETWORK;

export const getContract = () => {
  switch (NETWORK) {
    case "mainnet":
      return "0xD08aFED45F59117693218199108ddfE84C0A92bA";
    default:
      return "0x80FA71735642A7D9FADfa03F6401c3f62Ef40AfE";
  }
};

export const MINT_PRICE = NETWORK === "mainnet" ? 2 : 0.002;

const getChain = () => {
  switch (NETWORK) {
    case "mainnet":
      return {
        id: Mainnet.chainId,
        name: NETWORK,
      };
    default:
      return {
        id: 4, // Rinkeby
        name: NETWORK,
      };
  }
};

export const dAppsConfig = {
  readOnlyChainId: getChain().id,
  readOnlyUrls: {
    [getChain().id]: getDefaultProvider(getChain().name),
  },
};

export const infuraId = process.env.REACT_APP_INFURA_ID;

export const providerOptions = {
  injected: {
    display: {
      name: "Metamask",
      description: "Connect with the provider in your Browser",
    },
    package: null,
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      bridge: "https://bridge.walletconnect.org",
      infuraId: infuraId,
    },
  },
};
