import React from "react";

export const Header = (props: any) => {
  const { header, subHeader } = props

  return (
      <div className="header">
        <h1>{header}</h1>
        {subHeader && <h2>{subHeader}</h2>}

        <div className="line"/>
      </div>
  );
};
