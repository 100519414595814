import React from "react";

export const Promo = (props: any) => {
  return (
    <div className="promo-section">
      <div className="opacity" />
      <div className="wrapper flex-col center-center">
        <h1>
          <i>
            “Bringing Real Life Cannabis Business to the Metaverse. Welcome to
            ZEETOX NeverLand.”
          </i>
        </h1>
        <p>- IM -</p>
      </div>
    </div>
  );
};
