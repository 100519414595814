import React, { useEffect, useMemo, useState } from "react";
import Web3Modal from "web3modal";
import * as Configs from "../../config";
import * as Utils from "../../utils";
import { formatUnits } from "@ethersproject/units";
import { Contract } from "@ethersproject/contracts";
import abiJson from "../../abi/abi.json";
const {
  useEthers,
  useEtherBalance,
  useContractFunction,
} = require("@usedapp/core");

export const Mint = (props: any) => {
  const { account, activate, error } = useEthers();
  const etherBalance = useEtherBalance(account);
  const [mintNum, setMintNum] = useState(1);
  const [minting, setMinting] = useState(false);
  const [msg, setMsg] = useState<string | null | undefined>(null);
  const useDContract = new Contract(Configs.getContract(), abiJson);
  const { send, state } = useContractFunction(useDContract, "mint");

  const activateProvider = async () => {
    const providerOptions = Configs.providerOptions;

    const web3Modal = new Web3Modal({
      providerOptions,
    });
    try {
      const provider = await web3Modal.connect();
      await activate(provider);
    } catch (error) {}
  };

  const toMint = () => {
    if (!account) return activateProvider();
    if (minting) return;
    if (parseFloat(formatUnits(etherBalance, "ether")) < price)
      return setMsg("Sorry, you don't have enough ETH to purchase.");

    setMinting(true);
    setMsg("Minting...Please wait a second");
    let finalPrice = Utils.padZerosBehind(price, 18);
    send(mintNum, {
      value: finalPrice,
    });
  };

  const price = useMemo(() => {
    return Configs.MINT_PRICE * mintNum;
  }, [mintNum]);

  useEffect(() => {
    setMinting(false);
    if (state) {
      switch (state.status) {
        case "Exception":
          setMsg(state.errorMessage);
          break;
        case "PendingSignature":
        case "Mining":
          setMinting(true);
          break;
        case "Success":
          setMsg(`Mint success, your txID is ${state.transaction?.hash}`);
          break;
      }
    }
    // state = Pending Signature, Exception,
  }, [state, setMinting]);

  useEffect(() => {
    if (error && account) {
      setMsg(error.message);
      setMinting(false);
      return;
    }
  }, [error, account, setMsg, setMinting]);

  return (
    <div className="mint-section">
      <h1>ZEETOX</h1>
      <h2>- Zero Toxics -</h2>

      <div className="mint-blk">
        <div className="mint-selection">
          <div className="mint-num">
            <button onClick={() => setMintNum(mintNum > 1 ? mintNum - 1 : 1)}>
              -
            </button>
            <div className="mint-num-box">
              <h1>{mintNum}</h1>
            </div>
            <button onClick={() => setMintNum(mintNum + 1)}>+</button>
          </div>

          <div className="price-row">
            <img
              src="/assets/eth_new.png"
              alt="ETH-icon"
              width="67"
              height={67}
              style={{ marginRight: 15 }}
            />
            <h1>{price} ETH</h1>
          </div>

          <button onClick={() => toMint()}>
            {!account ? "Connect Wallet to Mint" : `Mint Now ${">"}`}
          </button>
          <div className="line" />
          {msg && <p>{msg}</p>}
        </div>
      </div>
    </div>
  );
};
