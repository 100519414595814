import React, { useEffect, useState } from "react";
import Web3Modal from "web3modal";
import * as Configs from "../../config";
const { useEthers } = require("@usedapp/core");

export const Web3Btn = () => {
  const { account, activate, deactivate } = useEthers();
  const [activateError, setActivateError] = useState("");
  const { error } = useEthers();

  useEffect(() => {
    if (error && account) {
      setActivateError(error.message);
      return;
    }
    setActivateError("");
  }, [error, account, setActivateError]);

  const activateProvider = async () => {
    const providerOptions = Configs.providerOptions;

    const web3Modal = new Web3Modal({
      providerOptions,
    });
    try {
      const provider = await web3Modal.connect();
      await activate(provider);
      setActivateError("");
    } catch (error) {
      const msg: any = error;
      setActivateError(msg.message);
    }
  };

  console.log(activateError); // TODO: Hide later

  return (
    <div className="web3-btn">
      {!account && (
        <button className="metamask" onClick={() => activateProvider()}>
          Connect
        </button>
      )}
      {account && (
        <button className="metamask" onClick={() => deactivate()}>
          Disconnect
        </button>
      )}
    </div>
  );
};
